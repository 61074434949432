import { lazy, Suspense, useCallback, useEffect, useReducer, useRef, useState } from "react";

import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import Grid from "@mui/material/Grid";
import ListItemIcon from "@mui/material/ListItemIcon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import { ReactComponent as AddTester } from "assets/icon/UsergroupAddOutlined.svg";
import GroupCard from "components/pages/acg_hr/group/main/groupCards/GroupCard";
import NavOpenBtn from "components/templates/NavOpenBtn";
import EmptyPlaceholder from "components/UI/organism/EmptyPlaceholder";
import LoadingCircle from "components/UI/organism/LoadingCircle";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import { ReactComponent as Spinner } from "assets/icon/downloadSpinner-white.svg";
import { useRecoilState, useRecoilValue, useSetRecoilState } from "recoil";
import { examMAPatom, HRcompanyInfo, perPageAtom } from "recoil/store";

import { useQueryClient } from "@tanstack/react-query";
import useGetFile from "hooks/useGetFile";
import useGetQueryhr from "hooks/useGetQueryhr";
import useMutationHook from "hooks/useMutationHook";

import { useLocation, useParams } from "react-router-dom";

import { Backdrop, OutlinedInput } from "@mui/material";
import { checkIcon, errorIcon, questionIcon, warningIcon } from "assets/icon/Icon_modal";
import { alertModalConfig, confirmModalConfig } from "components/templates/modalConfig";
import { MESSAGE_DOWNLOAD_GROUP_REPORT, MESSAGE_DOWNLOAD_INTERVIEWER_REPORT, MESSAGE_DOWNLOAD_PERSONAL_REPORT, MESSAGE_SCORING } from "enums/modalMessage";
import { GROUP_ROOM_LIST_NO_VIDEO, GROUP_TESTER_LIST_MORE_LIST, GROUP_TESTER_LIST_MORE_LIST_client } from "enums/tableColumn";
import { Database, Download, FileSpreadsheet, FileText, FunctionSquare, LayoutList, Search, Trash2, User2, Users2, UserSquare2 } from "lucide-react";
import { modalReducer, progressReducer } from "reducer/reducer";
import Swal from "sweetalert2";
import { tableHeader, tableWrapper } from "themes/theme";
import { checkboxSelect } from "utils/checkboxSelect";
import { bizCodeString } from "utils/formatter/bizCodeString";
import { dateFormatTime } from "utils/formatter/dateFormat";
import { YNColor } from "utils/formatter/YNColor";
import { examStatusTag } from "utils/tagRender";
import TopTitle from "../../../../../templates/topTitle/TopTitle";
import { DownloadProgressModalMemoized } from "../roomList/DownloadProgressModalMemoized";
import DownloadScoreTestModal from "../roomList/DownloadScoreTestModal";
import TesterStatusListDownloadModal from "../roomList/TesterStatusListDownloadModal";

const UpdateTesterModal = lazy(() => import("components/pages/acg_hr/group/main/roomList/testerMenu/UpdateRoomTesterModal"));
const TesterVideoLogModal = lazy(() => import("../roomList/testerMenu/TesterVideoLogModal"));
const IdCardCheckModal = lazy(() => import("../roomList/testerMenu/IdCardCheckModal"));
const UpdateTestStatusModal = lazy(() => import("components/pages/acg_hr/group/main/roomList/testerMenu/UpdateTestStatusModal"));
const TesterLogDataModal = lazy(() => import("components/pages/acg_hr/group/main/roomList/testerMenu/TesterLogDataModal"));

const CreateTesterModal = lazy(() => import("components/pages/acg_hr/group/main/roomList/CreateTesterModal"));

const DownloadGroupMarkDataModal = lazy(() => import("components/pages/acg_hr/group/main/roomList/DownloadGroupMarkDataModal"));
const TestSetStatus = lazy(() => import("components/pages/acg_hr/group/main/roomList/TestSetStatus"));

export default function TesterList() {
  const location = useLocation();
  const examMAP = useSetRecoilState(examMAPatom);
  const { id, company } = useParams();
  const [selected, setSelected] = useState([]);

  const [perPage, setPerPage] = useRecoilState(perPageAtom);
  const [reportDownloadToggle, setReportDownloadToggle] = useState(false);
  const [progressModalOn, setProgressModalOn] = useState(false);
  const [testerInfo, setTesterInfo] = useState({});
  const [markType, setMarkType] = useState("");
  const [showTester, setShowTester] = useState(null);
  const testerInputRef = useRef();
  const handleCloseDownloadMarkData = () => setDownloadMarkDataAnchorEl(null);
  const [paramValues, setParamValues] = useState({
    pageNo: 1,
    groupIdx: Number(id),
    perPage: 50,
  });
  const companyInfo = useRecoilValue(HRcompanyInfo);

  const [state, dispatch] = useReducer(modalReducer, { modal: null });
  const [progressModalState, dispatchProgressModalState] = useReducer(progressReducer, { modal: null });
  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleOpen = () => {
    setOpen(true);
  };

  const {
    data: groupInfo,
    isLoading: groupInfoDataLoading,
    isError: groupInfoDataError,
    refetch: groupInfoDataRefetch,
  } = useGetQueryhr("getGroupDetail", { groupIdx: id });
  console.log("groupInfo: ", groupInfo);

  testerInputRef.current?.focus();
  useEffect(() => {
    if (location.state) {
      setParamValues((prev) => ({
        ...prev,
        pageNo: location.state.pageNo,
        //* 응시자 검색으로 들어올 시 total정보가 없으므로 50으로 고정(변동가능)
        perPage: 50,
      }));

      setShowTester(location.state.testerIdx);
    }
  }, [location]);

  const queryClient = useQueryClient();

  const { data, pageInfo, isLoading, isFetching, isError, refetch } = useGetQueryhr("testerList", paramValues);

  const {} = useGetFile("HR", "reportDownloadGroup", { groupIdx: Number(id) }, reportDownloadToggle, setReportDownloadToggle);

  const [moreBtnAnchor, setMoreBtnAnchor] = useState(null);
  const openMoreBtn = Boolean(moreBtnAnchor);

  const handleClickMenu = (event, row) => {
    setMoreBtnAnchor(event.currentTarget);
    setTesterInfo(row);
  };

  useEffect(() => {
    groupInfo && examMAP(groupInfo.examMAP);
  }, [groupInfo]);

  useEffect(() => {
    const row = document.getElementById(`row${location?.state?.testerIdx}`);

    row &&
      row.scrollIntoView({
        behavior: "smooth",
        block: "center",
        inline: "nearest",
      });
  }, [data, location]);

  const handleCloseMoreBtn = () => {
    setMoreBtnAnchor(null);
  };
  const handleProgressModal = () => setProgressModalOn(!progressModalOn);
  //* 전체 HR 레포트 탬플릿 생성
  const { mutate: mutateAsync } = useMutationHook("groupHRreport", "HR");

  const [downloadAIdata, setDownloadAIdata] = useState(false);

  //* 감독관 템플릿 다운로드
  useGetFile("HR", "downloadAiData", { groupIdx: Number(id) }, downloadAIdata, setDownloadAIdata);

  //* 개인 PDF Report 다운로드
  const { mutate: downloadReport_personal, isLoading: downloadReport_personal_loading } = useMutationHook("personalReportIndividual", "HR");

  //* 응시자 삭재
  const { mutate: deleteTester } = useMutationHook("deleteTesters", "HR");

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    queryClient.invalidateQueries({
      queryKey: [key],
    });
  }, []);

  const handleDeleteTester = () => {
    Swal.fire(
      confirmModalConfig({
        text: "선택하신 응시자를 삭제하시겠습니까?",
        title: "응시자 삭제",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        deleteTester(
          { testerIdx: selected, groupIdx: Number(id) },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "응시자 삭제가 완료되었습니다.",
                  title: "응시자 삭제",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("testerList");
              });
            },
          }
        );
      }
    });
  };

  const handlePerPage = (e) => {
    setPerPage(e.target.value);
    setParamValues((prev) => ({
      ...prev,
      perPage: e.target.value,
    }));
  };

  //* CheckBox
  const handleCheckClick = (_, name) => {
    const { testerIdx } = name;
    const selectedIndex = selected.indexOf(testerIdx);
    setSelected(checkboxSelect(selected, selectedIndex, testerIdx));
  };
  const isSelected = (name) => selected.indexOf(name) !== -1;

  //* 더보기 메뉴
  /* 
  { index: 1, label: "응시자수정" },
  { index: 2, label: "응시자 진행상태 수정" },
  { index: 3, label: "개인리포트" },
  { index: 4, label: "접속로그 확인" },
  */
  const handleSelectMenu = (e, row) => {
    if (e.target.value === 1) handleOpenModal("updateTester");
    if (e.target.value === 2) handleOpenModal("testStatus");
    if (e.target.value === 3) handleOpenModal("idCardCheckModal"); //신분증
    if (e.target.value === 4) handleOpenModal("logData");
    if (e.target.value === 5) handleOpenModal("videoLog"); // 녹화영상
    if (e.target.value === 6) downloadReport(); // 개인 Report

    setMoreBtnAnchor(null);
  };

  const dialogMessage = useCallback((type) => {
    if (type === "personal") {
      return { text: MESSAGE_DOWNLOAD_PERSONAL_REPORT, title: "개인 레포트 다운로드" };
    } else if (type === "interviewer") {
      return { text: MESSAGE_DOWNLOAD_INTERVIEWER_REPORT, title: "면접관 레포트 다운로드" };
    } else {
      return { text: MESSAGE_DOWNLOAD_GROUP_REPORT, title: "그룹 레포트 다운로드" };
    }
  }, []);

  const downloadReport = (_, reason) => {
    Swal.fire(
      confirmModalConfig({
        text: "해당 응시자의 Report를 다운로드 하시겠습니까?",
        title: "개인 Report 다운로드",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        downloadReport_personal(
          {
            testerIdx: testerInfo.testerIdx,
          },
          {
            onSuccess: (res) => {
              window.open(`https://${res.data.data}`);
              handleClose();
            },
            onError: (error) => {
              Swal.fire(
                alertModalConfig({
                  title: "개인 Report 다운로드",
                  text: error.message,
                  iconHtml: errorIcon,
                  confirmButtonText: "확인",
                })
              ).then((res) => {
                if (res.isConfirmed) {
                  handleClose();
                }
              });
            },
          }
        );
      }
    });
  };

  //* Dispatch State
  const handleOpenProgressModal = (modal) => {
    handleCloseDownloadListMenu();
    handleCloseReportDownloadListMenu();
    if (companyInfo.companyIdx !== 1) {
      if (groupInfo.total >= 100) {
        Swal.fire(
          alertModalConfig({
            width: "auto",
            html: `
              <p>응시자 100명 이상 등록된 공고의 결과는 HR담당자 다운로드가 불가능합니다.</p>
              <p>ACG 담당자에게 별도 문의해 주시기 바랍니다.</p>
            `,
            title: "레포트 다운로드",
            iconHtml: warningIcon,
            confirmButtonText: "확인",
          })
        );
        return;
      }
    }

    if (modal !== "scoreTest") {
      Swal.fire(
        confirmModalConfig({
          text: dialogMessage(modal).text,
          title: dialogMessage(modal).title,
          iconHtml: questionIcon,
          confirmButtonText: "확인",
          cancelButtonText: "취소",
          focusConfirm: true,
          showLoaderOnConfirm: true,
        })
      ).then((result) => {
        if (result.isConfirmed) {
          dispatchProgressModalState({
            type: !progressModalState.modal ? modal : "close-modal",
            payload: { modal },
          });
        }
      });
    } else {
      dispatchProgressModalState({
        type: !progressModalState.modal ? modal : "close-modal",
        payload: { modal },
      });
    }
  };
  const handleOpenModal = (modal) => {
    handleCloseDownloadListMenu();
    dispatch({
      type: !state.modal ? modal : "close-modal",
      payload: { modal },
    });
  };
  const handleCloseModal = () => {
    dispatch({ type: "close-modal" });
  };

  const handleCloseProgressModal = () => {
    handleCloseDownloadListMenu();
    handleCloseReportDownloadListMenu();
    dispatchProgressModalState({ type: "close-modal" });
  };

  //* 전체선택 체크박스
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.data.map((n) => n.testerIdx);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleDownloadGroupMarkData = (_, type) => {
    handleCloseDownloadListMenu();
    handleCloseDownloadMarkData();
    setMarkType(type);
    handleOpenModal(type);
  };

  const [downloadAnchorEl, setDownloadAnchorEl] = useState(null);
  const [reportMenuEl, setReportMenuEl] = useState(null);
  const [downloadMarkDataAnchorEl, setDownloadMarkDataAnchorEl] = useState(null);
  const openDownloadMenu = Boolean(downloadAnchorEl);
  const openDownloadReportMenu = Boolean(reportMenuEl);
  const openDownloadMarkDataMenu = Boolean(downloadMarkDataAnchorEl);

  const handleOpenDownloadListMenu = (e) => setDownloadAnchorEl(e.currentTarget);
  const handleCloseDownloadListMenu = () => setDownloadAnchorEl(null);

  const handleCloseReportDownloadListMenu = () => setReportMenuEl(null);
  const handleOpenDownloadReportListMenu = (e) => setReportMenuEl(e.currentTarget);
  const handleOpenDownloadMarkData = (e) => setDownloadMarkDataAnchorEl(e.currentTarget);

  const handleSearchTester = () => {
    setParamValues((prev) => ({
      ...prev,
      pageNo: 1,
      searchInfo: { testerKeyword: testerInputRef.current.value },
    }));
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearchTester();
    }
  };

  const handleScoring = () => {
    Swal.fire(
      confirmModalConfig({
        html: MESSAGE_SCORING,
        title: "응시 결과처리",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        handleOpenProgressModal("scoreTest");
      }
    });
  };

  useEffect(() => {
    if (downloadReport_personal_loading) {
      handleOpen();
    }
  }, [downloadReport_personal_loading]);

  return (
    <Box width="100%" height="100%" borderRadius={2} display="flex" flexDirection="column" rowGap={2}>
      <NavOpenBtn />
      <Grid container justifyContent={"space-between"}>
        <TopTitle
          list={[
            {
              title: "채용그룹 관리",
              url: `/acghr_hr/${company}/group`,
              current: false,
            },
            {
              title: "응시자 목록",
              url: `/acghr_hr/${company}/testerList${id}`,
              current: true,
            },
          ]}
        />

        <Box>
          <Grid item container columnGap={1}>
            {companyInfo.companyIdx === 1 ? (
              <>
                <Button variant="outlined" onClick={() => handleOpenModal("testSetStatus")} startIcon={<FileSpreadsheet strokeWidth={1.2} size={18} />}>
                  검사 SET 현황
                </Button>
                <Button startIcon={<FunctionSquare size={17} strokeWidth={1.3} />} onClick={handleScoring} variant="outlined">
                  결과 처리
                </Button>
              </>
            ) : null}
            <Button startIcon={<Download size={15} />} onClick={handleOpenDownloadListMenu} variant="outlined">
              다운로드
            </Button>
            <Menu anchorEl={downloadAnchorEl} open={openDownloadMenu} onClose={handleCloseDownloadListMenu}>
              {companyInfo.companyIdx === 1 ? (
                <MenuItem
                  sx={{
                    "&>.MuiListItemIcon-root": {
                      minWidth: 25,
                    },
                  }}
                  onClick={handleOpenDownloadMarkData}
                >
                  <ListItemIcon>
                    <Database size={17} />
                  </ListItemIcon>
                  응답값
                </MenuItem>
              ) : null}

              <MenuItem
                sx={{
                  "&>.MuiListItemIcon-root": {
                    minWidth: 25,
                  },
                }}
                onClick={handleOpenDownloadReportListMenu}
              >
                <ListItemIcon>
                  <FileText size={17} />
                </ListItemIcon>
                레포트
              </MenuItem>

              <MenuItem
                sx={{
                  "&>.MuiListItemIcon-root": {
                    minWidth: 25,
                  },
                }}
                onClick={() => handleOpenModal("testerStatusList")}
              >
                <ListItemIcon>
                  <LayoutList size={17} />
                </ListItemIcon>
                응시자 현황
              </MenuItem>
            </Menu>
            {/* 레포트 */}
            <Menu
              anchorEl={reportMenuEl}
              open={openDownloadReportMenu}
              onClose={handleCloseReportDownloadListMenu}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                sx={{
                  "&>.MuiListItemIcon-root": {
                    minWidth: 25,
                  },
                }}
                // onClick={handleCreateGroupHRreport}
                onClick={() => handleOpenProgressModal("hr")}
              >
                <ListItemIcon>
                  <Users2 size={17} />
                </ListItemIcon>
                그룹
              </MenuItem>
              <MenuItem
                sx={{
                  "&>.MuiListItemIcon-root": {
                    minWidth: 25,
                  },
                }}
                onClick={() => handleOpenProgressModal("personal")}
              >
                <ListItemIcon>
                  <User2 size={17} />
                </ListItemIcon>
                개인
              </MenuItem>
              <MenuItem
                sx={{
                  "&>.MuiListItemIcon-root": {
                    minWidth: 25,
                  },
                }}
                onClick={() => handleOpenProgressModal("interviewer")}
              >
                <ListItemIcon>
                  <UserSquare2 size={17} />
                </ListItemIcon>
                면접관
              </MenuItem>
            </Menu>
            {/* 응답값 */}
            <Menu
              anchorEl={downloadMarkDataAnchorEl}
              open={openDownloadMarkDataMenu}
              onClose={handleCloseDownloadMarkData}
              anchorOrigin={{
                vertical: "top",
                horizontal: "left",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
            >
              <MenuItem
                sx={{
                  "&>.MuiListItemIcon-root": {
                    minWidth: 25,
                  },
                }}
                onClick={(_) => handleDownloadGroupMarkData(_, "MARK")}
              >
                <ListItemIcon>
                  <Users2 size={17} />
                </ListItemIcon>
                Raw Data
              </MenuItem>

              <MenuItem
                sx={{
                  "&>.MuiListItemIcon-root": {
                    minWidth: 25,
                  },
                }}
                onClick={(_) => handleDownloadGroupMarkData(_, "INTERVAL")}
              >
                <ListItemIcon>
                  <User2 size={17} />
                </ListItemIcon>
                풀이시간
              </MenuItem>
            </Menu>
          </Grid>
        </Box>
      </Grid>
      <Grid>
        {groupInfo && (
          <Card
            variant="outlined"
            sx={{
              background: "transparent",
              border: "none",
              "& .MuiCardContent-root:last-child": { padding: 0 },
            }}
          >
            <CardContent>
              <GroupCard selectedItem={groupInfo} status={isLoading || isFetching} />
            </CardContent>
          </Card>
        )}
      </Grid>

      <Grid container alignItems="center" justifyContent={"space-between"} flexWrap="nowrap">
        <Grid item container alignItems="center" columnGap={1}>
          <Stack direction="row" columnGap={1}>
            <OutlinedInput
              size="small"
              inputRef={testerInputRef}
              placeholder={`성명 또는 휴대전화 번호를 입력해 주세요.`}
              onKeyUp={(e) => handleKeyPress(e, "searchEnter")}
              sx={{ width: 300 }}
            />
            <Button variant="contained" disableElevation startIcon={<Search size={15} strokeWidth={1.3} />} onClick={handleSearchTester}>
              검색
            </Button>
          </Stack>
          <Typography fontWeight={700}>{`총 ${pageInfo.total.toLocaleString()}명`}</Typography>
        </Grid>
        <Grid item container columnGap={1} justifyContent="flex-end">
          <Grid item>
            <Button
              variant="contained"
              onClick={() => handleOpenModal("addTester")}
              sx={{
                "&>span>svg": { width: 20, height: 20 },
              }}
              disableElevation
              startIcon={<AddTester />}
            >
              응시자 등록/추가
            </Button>
          </Grid>

          <Grid item>
            <Button
              startIcon={<Trash2 size={15} />}
              variant="contained"
              color="removeButton"
              disableElevation
              onClick={handleDeleteTester}
              sx={{ "&": { color: "#c42c08" } }}
              disabled={selected.length <= 0 ? true : false}
            >
              응시자 삭제
            </Button>
          </Grid>
        </Grid>
      </Grid>

      {data && data.data.length < 1 ? (
        <Box bgcolor={"white"} borderRadius={2} height={"100%"} display="flex" alignItems={"center"} justifyContent="center" py={10}>
          <EmptyPlaceholder />
        </Box>
      ) : (
        <>
          <Paper
            sx={{
              ...tableWrapper,
              overflowY: "hidden",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "space-between",
            }}
            variant="outlined"
          >
            {isLoading || isFetching ? (
              <LoadingCircle />
            ) : (
              <>
                <TableContainer sx={{ height: "calc(95% - 20px)" }}>
                  <Table stickyHeader sx={tableHeader} size="small">
                    <TableHead>
                      <TableRow>
                        <TableCell padding="checkbox" align="center">
                          <Checkbox size="small" style={{ color: "#637381" }} onChange={handleSelectAllClick} />
                        </TableCell>
                        {GROUP_ROOM_LIST_NO_VIDEO.map((item) => (
                          <TableCell key={item.label} sx={{ width: item.size, fontWeight: "bold" }} align="center">
                            {item.label}
                          </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {data &&
                        data.data?.map((row) => (
                          <TableRow
                            hover
                            key={row.testerIdx}
                            sx={{
                              "&": { height: 43 },
                              "&:last-child td, &:last-child th": { border: 0 },
                            }}
                            role="checkbox"
                            tabIndex={-1}
                            className={row.testerIdx === showTester ? "roomTesterFocus" : ""}
                            id={`row${row.testerIdx}`}
                          >
                            <TableCell padding="none" align="center">
                              <Checkbox size="small" color="primary" onClick={(event) => handleCheckClick(event, row)} checked={isSelected(row.testerIdx)} />
                            </TableCell>
                            <TableCell padding="none" align="center" component="td" scope="row">
                              {bizCodeString(row.bizCode)}
                            </TableCell>
                            <TableCell padding="none" align="center">
                              {row.testerName}
                            </TableCell>
                            <TableCell padding="none" align="center">
                              {row.testerBirth}
                            </TableCell>
                            <TableCell padding="none" align="center">
                              {row.testerCell}
                            </TableCell>
                            <TableCell padding="none" align="center">
                              {YNColor(row.foreignLoginYN)}
                            </TableCell>

                            <TableCell padding="none" align="center">
                              {dateFormatTime(row.logAt)}
                            </TableCell>
                            <TableCell padding="none" align="center">
                              {row.normName}
                            </TableCell>
                            <TableCell padding="none" align="center">
                              {examStatusTag(row.examStatus)}
                            </TableCell>
                            <TableCell padding="none" align="center">
                              <Button onClick={(e) => handleClickMenu(e, row)}>
                                <MoreHorizIcon />
                              </Button>
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
                <Menu anchorEl={moreBtnAnchor} open={openMoreBtn} onClose={handleCloseMoreBtn}>
                  {companyInfo.companyIdx === 1
                    ? GROUP_TESTER_LIST_MORE_LIST.map((item, index) => {
                        return (
                          <MenuItem key={index} onClick={(e) => handleSelectMenu(e, testerInfo)} value={item.index}>
                            {item.label}
                          </MenuItem>
                        );
                      })
                    : GROUP_TESTER_LIST_MORE_LIST_client.map((item, index) => {
                        return (
                          <MenuItem key={index} onClick={(e) => handleSelectMenu(e, testerInfo)} value={item.index}>
                            {item.label}
                          </MenuItem>
                        );
                      })}
                </Menu>
                <Grid container alignItems={"center"} justifyContent="center" my={1}>
                  <Pagination
                    size="small"
                    count={pageInfo.totalPage}
                    page={pageInfo.pageNo}
                    onChange={(_, page) => {
                      setParamValues((prev) => ({ ...prev, pageNo: page }));
                    }}
                  />
                </Grid>
              </>
            )}
          </Paper>
        </>
      )}

      {/* 응시자 정보수정 */}
      <Dialog open={state.modal === "updateTester"} onClose={handleCloseModal} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingSkeleton />}>
          <UpdateTesterModal detail={selected} roomTesterInfo={testerInfo} onClose={handleCloseModal} queryKey="testerList" />
        </Suspense>
      </Dialog>

      {/* 응시자 진행상태 정보 수정 */}
      <Dialog open={state.modal === "testStatus"} onClose={handleCloseModal} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingSkeleton />}>
          <UpdateTestStatusModal detail={selected} roomTesterInfo={testerInfo} onClose={handleCloseModal} />
        </Suspense>
      </Dialog>

      {/* 응시자 접속로그 데이터 모달 */}
      <Dialog open={state.modal === "logData"} onClose={handleCloseModal} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingSkeleton />}>
          <TesterLogDataModal roomTesterInfo={testerInfo} onClose={handleCloseModal} />
        </Suspense>
      </Dialog>

      {/* 응시자 등록 모달 */}
      <Dialog open={state.modal === "addTester"} onClose={handleCloseModal} fullWidth maxWidth="sm">
        <Suspense fallback={<LoadingSkeleton />}>
          <CreateTesterModal queryKey="testerList" groupIdx={id} onClose={handleCloseModal} />
        </Suspense>
      </Dialog>

      {/* 그룹 마킹값 다운로드 */}
      <Dialog open={state.modal === "MARK" || state.modal === "INTERVAL"} onClose={handleCloseModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <DownloadGroupMarkDataModal groupIdx={id} onClose={handleCloseModal} markType={markType} />
        </Suspense>
      </Dialog>

      {/* 응시자 현황 다운로드 */}
      <Dialog open={state.modal === "testerStatusList"} onClose={handleCloseModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <TesterStatusListDownloadModal groupIdx={id} onClose={handleCloseModal} />
        </Suspense>
      </Dialog>
      {/* 검사세트 현황 */}
      <Dialog open={state.modal === "testSetStatus"} onClose={handleCloseModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <TestSetStatus onClose={handleCloseModal} />
        </Suspense>
      </Dialog>

      {/* 신분증 확인 모달 */}
      <Dialog open={state.modal === "idCardCheckModal"} onClose={handleCloseModal} fullWidth maxWidth="md">
        <Suspense fallback={<LoadingCircle />}>
          <IdCardCheckModal roomTesterInfo={testerInfo} onClose={handleCloseModal} />
        </Suspense>
      </Dialog>
      {/* 비디오로그 모달 */}
      <Dialog open={state.modal === "videoLog"} onClose={handleCloseModal} fullWidth maxWidth="lg">
        <Suspense fallback={<LoadingSkeleton />}>
          <TesterVideoLogModal roomTesterInfo={testerInfo} onClose={handleCloseModal} />
        </Suspense>
      </Dialog>
      {/* Progress Modal */}
      <Dialog open={progressModalState.modal === "personal"} onClose={handleProgressModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <DownloadProgressModalMemoized mutateId="personalHRreport" queryId="reportDownloadPersonal" onClose={handleCloseProgressModal} id={Number(id)} />
        </Suspense>
      </Dialog>
      {/* HR 레포트 */}
      <Dialog open={progressModalState.modal === "hr"} onClose={handleCloseProgressModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <DownloadProgressModalMemoized mutateId="groupHRreport" queryId="reportDownloadHR" onClose={handleCloseProgressModal} id={Number(id)} />
        </Suspense>
      </Dialog>

      {/* Progress Modal */}
      <Dialog open={progressModalState.modal === "interviewer"} onClose={handleCloseProgressModal} fullWidth maxWidth="xs">
        <Suspense fallback={<LoadingCircle />}>
          <DownloadProgressModalMemoized mutateId="interviewerReport" queryId="reportDownloadInterviewer" onClose={handleCloseProgressModal} id={Number(id)} />
        </Suspense>
      </Dialog>

      {/* Progress Modal */}
      <Dialog
        open={progressModalState.modal === "scoreTest"}
        // onClose={handleCloseProgressModal}
        fullWidth
        maxWidth="xs"
      >
        <Suspense fallback={<LoadingCircle />}>
          <DownloadScoreTestModal id={Number(id)} onClose={handleCloseProgressModal} />
        </Suspense>
      </Dialog>
      <Backdrop sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }} open={open}>
        <Stack direction="column" rowGap={1} alignItems="center">
          <Spinner />
          <Typography
            component="div"
            color="white"
            fontWeight={700}
            sx={{
              clipPath: "inset(0 2.5ch 0 0)",
              animation: "1s steps(4) infinite",
              fontSize: "15px",
              animationName: "l",
              "@keyframes l": {
                to: {
                  clipPath: "inset(0 -1ch 0 0)",
                },
              },
            }}
          >
            레포트 정보를 생성중입니다. . .
          </Typography>
        </Stack>
      </Backdrop>
    </Box>
  );
}
