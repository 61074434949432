import { useEffect } from "react";

import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";

import { EXAM_MAP_0, EXAM_MAP_1, EXAM_MAP_2 } from "enums/examMAP";
import "flatpickr/dist/themes/airbnb.css";
import { Search } from "lucide-react";
import { useRecoilValue } from "recoil";
import { searchGroupAtom } from "recoil/store";

export default function SearchGroupList({ submitSearch, searchRef, filterCondition, setFilterCondition }) {
  const searchGroupInfo = useRecoilValue(searchGroupAtom);

  const handleSubmitValues = (e) => {
    const { name, value } = e.target;
    setFilterCondition((prev) => ({ ...prev, [name]: value }));
  };

  useEffect(() => {
    searchRef.current.focus();
  }, []);

  return (
    <Grid container columnGap={1}>
      <TextField select label="감독 방식" size="small" name="roomUseYN" onChange={handleSubmitValues} value={searchGroupInfo?.roomUseYN} sx={{ minWidth: 100 }}>
        <MenuItem key={"total"} value={"TOTAL"}>
          전체
        </MenuItem>
        <MenuItem key={"Y"} value={"Y"}>
          화상감독
        </MenuItem>
        <MenuItem key={"N"} value={"N"}>
          무감독
        </MenuItem>
      </TextField>
      <TextField select label="검사유형" size="small" name="examMAP" onChange={handleSubmitValues} value={searchGroupInfo?.examMAP} sx={{ minWidth: 100 }}>
        <MenuItem key={"total"} value={"TOTAL"}>
          전체
        </MenuItem>
        <MenuItem key={"0"} value={"0"}>
          {EXAM_MAP_0}
        </MenuItem>
        <MenuItem key={"2"} value={"2"}>
          {EXAM_MAP_2}
        </MenuItem>
        <MenuItem key={"1"} value={"1"}>
          {EXAM_MAP_1}
        </MenuItem>
      </TextField>

      <Grid item>
        <TextField
          size="small"
          defaultValue={searchGroupInfo.groupFind}
          inputRef={searchRef}
          sx={{ minWidth: 300 }}
          placeholder="공고명 또는 검사시작 날짜를 입력해주세요"
          onKeyDown={(e) => {
            if (e.key === "Enter") submitSearch();
          }}
        />
      </Grid>
      <Grid item>
        <Button startIcon={<Search size={15} />} sx={{ height: "100%" }} variant={"outlined"} onClick={submitSearch}>
          검색
        </Button>
      </Grid>
    </Grid>
  );
}
