import { atom } from "recoil";
import { jwtDecoder } from "utils/jwtDecoder";
import { recoilPersist } from "recoil-persist";
import { timeFormat } from "utils/formatter/dateFormat";
const { persistAtom } = recoilPersist({
  storage: window.sessionStorage,
});

const company = window.location.pathname.split("/")[2];

const date = new Date();
export const isNavOpen = atom({
  key: "navOpen",
  default: true,
});

export const isLayoutPage = atom({
  key: "layoutPage",
  default: false,
});

export const companyIdxAtom = atom({
  key: "companyIdx",
  default: 0,
});

export const companyInfoAtom = atom({
  key: "companyInfo",
  default: {},
});

export const perPageAtom = atom({
  key: "perPage",
  default: 20,
});
export const searchGroupAtom = atom({
  key: "searchGroup",
  default: { pageNo: 1, perPage: 20, groupFind: "" },
});

export const statsFilterAtom = atom({
  key: "statsFilterAtom",
  default: {
    eExamDate: timeFormat(new Date(new Date(date.getFullYear(), date.getMonth() + 1, 0))),
    sExamDate: timeFormat(new Date(new Date(date.getFullYear(), date.getMonth(), 1))),
    pageNo: 1,
  },
  // effects_UNSTABLE: [persistAtom],
});

export const roomInfoAtom = atom({
  key: "roomInfo",
  default: {},
});

export const HRcompanyInfo = atom({
  key: "HR",
  default: jwtDecoder(sessionStorage.getItem(company) || localStorage.getItem(company)),
});

export const searchTesterValue = atom({
  key: "tester",
  default: "",
});

export const roomsAtom = atom({
  key: "rooms",
  default: [],
});

export const modalAtom = atom({
  key: "modal",
  default: { modalState: false },
});
export const isQuestionDetailPage = atom({
  key: "isQuestionDetailPage",
  default: false,
});

export const examMAPatom = atom({
  key: "examMAP",
  default: "0",
});

export const paginationAtom = atom({
  key: "pagination",
  default: 1,
});

//* 예제관리 1차 요청
export const practiceDetailAtom = atom({
  key: "practiceDetail",
  default: {},
});

//* 예제관리 2차 요청
export const practicePageDetailAtom = atom({
  key: "practicePageDetailAtom",
  default: {},
});

//* 문항관리 1차 요청
export const questionsDetailAtom = atom({
  key: "questionsDetail",
  default: {},
});

//* 문항관리 2차 요청
export const questionPageDetailAtom = atom({
  key: "questionPageDetailAtom",
  default: null,
});
