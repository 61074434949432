export const modalReducer = (state, action) => {
  switch (action.type) {
    case "close-modal":
      return { modal: null };
    case "idCardCheckModal":
      return { modal: "idCardCheckModal" };
    case "videoLog":
      return { modal: "videoLog" };
    case "logData":
      return { modal: "logData" };
    case "testStatus":
      return { modal: "testStatus" };
    case "updateTester":
      return { modal: "updateTester" };
    case "testSetStatus":
      return { modal: "testSetStatus" };
    case "addTester":
      return { modal: "addTester" };
    case "addManager":
      return { modal: "addManager" };
    case "sendMessage":
      return { modal: "sendMessage" };
    case "AI":
      return { modal: "AI" };
    case "testerStatusList":
      return { modal: "testerStatusList" };
    case "MARK":
      return { modal: "MARK" };
    case "INTERVAL":
      return { modal: "INTERVAL" };
    case "testerList":
      return { modal: "testerList" };
    default:
      return state;
  }
};

export const progressReducer = (state, action) => {
  switch (action.type) {
    case "close-modal":
      return { modal: null };
    case "personal":
      return { modal: "personal" };
    case "hr":
      return { modal: "hr" };
    case "interviewer":
      return { modal: "interviewer" };
    case "scoreTest":
      return { modal: "scoreTest" };
    case "customized":
      return { modal: "customized" };
    default:
      return state;
  }
};
