import { lazy, Suspense, useCallback, useEffect, useMemo, useRef, useState } from "react";

// Style
import NoPhotographyIcon from "@mui/icons-material/NoPhotography";
import PhotoCameraIcon from "@mui/icons-material/PhotoCamera";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Dialog from "@mui/material/Dialog";
import Fade from "@mui/material/Fade";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import MenuItem from "@mui/material/MenuItem";
import Pagination from "@mui/material/Pagination";
import Paper from "@mui/material/Paper";
import Select from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";

import { tableHeader, tableWrapper } from "themes/theme";
import { checkIcon, errorIcon, questionIcon } from "assets/icon/Icon_modal";
import { TableSortLabel } from "@mui/material";
import { visuallyHidden } from "@mui/utils";
// Component
import SearchGroupList from "components/pages/acg_hr/group/read/SearchGroupList";
import EmptyPlaceholder from "components/UI/organism/EmptyPlaceholder";
import TopTitle from "components/templates/topTitle/TopTitle";
import { ConfirmSwitch } from "components/UI/ConfirmSwitch";
import LoadingSkeleton from "components/UI/organism/LoadingSkeleton";
import { Download, Plus, Settings, Trash2, UndoDot } from "lucide-react";
import RemovedGroupModal from "./removedGroup/RemovedGroupModal";
// Router
import { Link, useNavigate, useParams } from "react-router-dom";

// React-query
import useGetQueryhr from "hooks/useGetQueryhr";
import { useQueryClient } from "@tanstack/react-query";
import useMutationHook from "hooks/useMutationHook";

// Dialog
import { alertModalConfig, confirmModalConfig } from "components/templates/modalConfig";
import Swal from "sweetalert2";

// ENUM
import { MESSAGE_CANCEL_CONFIRM_GROUP, MESSAGE_CONFIRM_GROUP, MESSAGE_DELETE_GROUP } from "enums/modalMessage";
import { GROUP_TABLE_ROW } from "enums/tableColumn";

// Recoil
import { useRecoilState, useRecoilValue } from "recoil";
import { HRcompanyInfo, perPageAtom, searchGroupAtom } from "recoil/store";

// Utils

import { checkboxSelect } from "utils/checkboxSelect";
import { dateIncludeTime, loginEnableTimeFormat } from "utils/formatter/dateFormat";
import { tagRender } from "utils/tagRender";
import { examMAPtype } from "../../../../../utils/examMAPtype";

const defaultFilterValues = {
  roomUseYN: "",
  examMAP: "TOTAL",
};

const CreateGroupForm = lazy(() => import("components/pages/acg_hr/group/create/CreateGroupForm"));
const UpdateGroup = lazy(() => import("components/pages/acg_hr/group/update/UpdateGroup"));

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc" ? (a, b) => descendingComparator(a, b, orderBy) : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
//* TABLE HEAD WITH SORT
function EnhancedTableHead(props) {
  const { onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort, headCells } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const companyInfo = useRecoilValue(HRcompanyInfo);

  let HRmanager = [];
  const headFlag = headCells;
  if (companyInfo.companyIdx !== 1) {
    HRmanager = headFlag.filter((head) => head.id !== "approveYN");
  } else {
    HRmanager = headCells;
  }
  return (
    <TableHead>
      <TableRow>
        <TableCell padding="none" align="center" width={10}>
          <Checkbox size="small" color="primary" checked={rowCount > 0 && numSelected === rowCount} onChange={onSelectAllClick} disabled />
        </TableCell>
        {HRmanager.map((headCell) =>
          headCell.id === "companyName" || headCell.id === "examStatus" || headCell.id === "modify" || headCell.id === "approveYN" ? (
            <TableCell padding="none" key={headCell.label} width={headCell.size} sx={{ fontWeight: "bold" }} align="center">
              {headCell.label}
            </TableCell>
          ) : (
            <TableCell width={headCell.size} key={headCell.id} align="center" padding="none" sortDirection={orderBy === headCell.id ? order : false}>
              <TableSortLabel
                hideSortIcon
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : "asc"}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            </TableCell>
          )
        )}
      </TableRow>
    </TableHead>
  );
}

export default function GroupList() {
  const navigate = useNavigate();
  const [modalOn, setModalOn] = useState(false);
  const [selected, setSelected] = useState([]);
  const [filterCondition, setFilterCondition] = useState(defaultFilterValues);

  const [selectedRow, setSelectedRow] = useState({});
  const [order, setOrder] = useState("asc");
  const [orderBy, setOrderBy] = useState("examSAT");

  const [perPage, setPerPage] = useRecoilState(perPageAtom);

  const searchRef = useRef("");
  const queryClient = useQueryClient();
  const { company } = useParams();
  const companyInfo = useRecoilValue(HRcompanyInfo);
  const [searchGroupInfo, setSearchGroupInfo] = useRecoilState(searchGroupAtom);
  console.log("🚀 ~ GroupList ~ searchGroupInfo:", searchGroupInfo);

  //* Mutation 후 이벤트
  const alertResult = useCallback((key) => {
    queryClient.invalidateQueries({
      queryKey: [key],
    });
    setSelected([]);
  }, []);

  if (company !== "ACG") GROUP_TABLE_ROW[3].flag = false;

  const { data, pageInfo, isLoading, isFetching, isError, refetch } = useGetQueryhr("totalGroup", searchGroupInfo);
  //* 그룹 삭제 Mutation
  const { mutate: deleteGroup } = useMutationHook("deleteGroup", "HR");

  //*  확정 Mutation
  const { mutate } = useMutationHook("confirmGroup", "HR");

  //*  공고목록 다운로드 Mutation
  const { mutate: downloadGroupList } = useMutationHook("downloadGroupList", "HR");
  const handleModal = () => setModalOn(!modalOn);

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.data.map((n) => n.groupIdx);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleCheckClick = (_, name) => {
    const { groupIdx } = name;
    const selectedIndex = selected.indexOf(groupIdx);
    setSelected(checkboxSelect(selected, selectedIndex, groupIdx));
    setSelectedRow(name);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  //* MODAL
  const [updateGroup, setUpdateGroup] = useState(false);
  const handleUpdateGroupModal = (_, row) => {
    setUpdateGroup(!updateGroup);
  };
  const [removedGroupModalOn, setRemovedGroupModalOn] = useState(false);
  const handleRemovedGroup = () => {
    setRemovedGroupModalOn((prev) => !prev);
  };

  const handlePerPage = (e) => {
    setPerPage(e.target.value);
    setSearchGroupInfo((prev) => ({
      ...prev,
      perPage: e.target.value,
    }));
  };

  const handleConfirmGroup = (_, row) => {
    Swal.fire(
      confirmModalConfig({
        text: row.approveYN === "Y" ? MESSAGE_CANCEL_CONFIRM_GROUP : MESSAGE_CONFIRM_GROUP,

        title: "공고 확정",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        mutate(
          {
            groupIdx: row.groupIdx,
            approveYN: row.approveYN === "Y" ? "N" : "Y",
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "확정 여부 수정이 완료되었습니다.",
                  title: "공고확정",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) {
                  queryClient.invalidateQueries({
                    queryKey: ["totalGroup"],
                  });
                }
              });
            },
            onError: (error) => {
              Swal.fire(
                alertModalConfig({
                  text: "확정 여부 수정을 실패하였습니다.",
                  title: "공고 확정",
                  iconHtml: errorIcon,
                  confirmButtonText: "확인",
                })
              );
            },
          }
        );
      }
    });
  };

  const handleClickRow = (e, row) => {
    if (e.target.cellIndex <= 7) {
      if (row.roomUseYN === "Y") navigate(`/acghr_hr/${company}/roomList${row.groupIdx}`);
      else navigate(`/acghr_hr/${company}/testerList${row.groupIdx}`);
    }
  };

  const handleDelete = () => {
    Swal.fire(
      confirmModalConfig({
        text: MESSAGE_DELETE_GROUP,
        title: "공고 삭제",
        iconHtml: questionIcon,
        confirmButtonText: "확인",
        cancelButtonText: "취소",
      })
    ).then((result) => {
      if (result.isConfirmed) {
        deleteGroup(
          {
            groupIdx: selected[0],
          },
          {
            onSuccess: () => {
              Swal.fire(
                alertModalConfig({
                  text: "공고 삭제가 완료되었습니다.",
                  title: "공고 삭제",
                  iconHtml: checkIcon,
                  confirmButtonText: "확인",
                })
              ).then((result) => {
                if (result.isConfirmed) alertResult("totalGroup");
              });
            },
          }
        );
      }
    });
  };

  const handleDownloadGroupList = () => {
    downloadGroupList(
      {
        conditions: searchGroupInfo,
      },
      {
        onError: () => {
          Swal.fire(
            alertModalConfig({
              text: "공고 다운로드를 실패하였습니다.",
              title: "공고 다운로드",
              iconHtml: errorIcon,
              confirmButtonText: "확인",
            })
          );
        },
        onSuccess: (res) => {
          window.open(`https://${res.data.data}`);
        },
      }
    );
  };

  const loginEnableTime = (input) => {
    return loginEnableTimeFormat(input);
  };
  const submitSearch = () => {
    let searchContent = {
      groupFind: searchRef.current.value,
    };

    console.log("🚀 ~ submitSearch ~ filterCondition:", filterCondition)
    let submit = { ...searchContent, ...filterCondition };
    console.log("🚀 ~ submitSearch ~ submit:", submit);

    // 필터만 적용했을 경우
    for (const key in submit) {
      if (submit[key] === "TOTAL") delete submit[key];
    }

    // 필터만 적용했을 경우
    for (const key in submit) {
      if (key === "") delete submit[""];
    }

    // 필터 미적용 항목 삭제
    for (const key in submit) {
      if (submit[key] === "") delete submit[key];
    }

    setSearchGroupInfo({ pageNo: 1, groupFind: searchRef.current.value, ...submit });
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const visibleRows = useMemo(() => {
    if (data) {
      return stableSort(data?.data, getComparator(order, orderBy));
    }
  }, [data, order, orderBy, pageInfo, perPage]);

  useEffect(() => {
    refetch();
  }, [searchGroupInfo]);

  return (
    <>
      <Box width="100%" height="100%" borderRadius={2} display="flex" flexDirection="column" rowGap={2}>
        <TopTitle
          list={[
            {
              title: "채용그룹 관리",
              url: `/acghr_hr/${company}/group`,
              current: true,
            },
          ]}
        />

        {isFetching || isLoading ? (
          <LoadingSkeleton />
        ) : (
          <Paper
            sx={{
              ...tableWrapper,
              overflowY: "hidden",
              height: "100%",
              display: "flex",
              flexDirection: "column",
              borderRadius: 2,
              p: 2,
              rowGap: 2,
            }}
            variant="outlined"
          >
            <SearchGroupList submitSearch={submitSearch} searchRef={searchRef} filterCondition={filterCondition} setFilterCondition={setFilterCondition} />

            <Stack direction={"row"} alignItems="center" justifyContent="space-between">
              <Box>
                <Grid item sm={12} container columnGap={2} alignItems="center">
                  <Select size="small" defaultValue={perPage} onChange={handlePerPage}>
                    <MenuItem key={10} value={10}>
                      10/Page
                    </MenuItem>
                    <MenuItem key={20} value={20}>
                      20/Page
                    </MenuItem>
                    <MenuItem key={50} value={50}>
                      50/Page
                    </MenuItem>
                  </Select>
                </Grid>
              </Box>
              <Stack direction={"row"} spacing={1}>
                <Button disableElevation variant="contained" onClick={handleModal} startIcon={<Plus size={15} />}>
                  공고등록
                </Button>
                <Button variant="outlined" onClick={handleDownloadGroupList} startIcon={<Download size={15} />}>
                  공고 다운로드
                </Button>

                <Button
                  variant="outlined"
                  disableElevation
                  sx={{
                    "&": {
                      color: "#08b41a",
                      fontWeight: 700,
                    },
                  }}
                  startIcon={<UndoDot size={15} />}
                  onClick={handleRemovedGroup}
                >
                  공고 복원
                </Button>
                <Tooltip TransitionComponent={Fade} title="한번에 1개씩만 삭제 가능합니다." arrow placement="top-start">
                  <Box component="span">
                    <Button
                      startIcon={<Trash2 size={15} />}
                      variant="contained"
                      color="removeButton"
                      disableElevation
                      disabled={selected.length === 0 || selected.length >= 2 ? true : false}
                      onClick={handleDelete}
                      sx={{ "&": { color: "#c42c08" } }}
                    >
                      공고삭제
                    </Button>
                  </Box>
                </Tooltip>
              </Stack>
            </Stack>
            <TableContainer sx={{ height: "calc(95% - 20px)" }}>
              <Table stickyHeader sx={tableHeader} size="small">
                <EnhancedTableHead
                  numSelected={selected.length}
                  order={order}
                  orderBy={orderBy}
                  onSelectAllClick={handleSelectAllClick}
                  onRequestSort={handleRequestSort}
                  rowCount={20}
                  headCells={GROUP_TABLE_ROW}
                />

                <TableBody>
                  {data && data.data?.length < 1 ? (
                    <TableRow
                      sx={{
                        "&:last-child td, &:last-child th": { border: 0 },
                      }}
                    >
                      <TableCell colSpan={17} height={300}>
                        <EmptyPlaceholder message={"조회내용이 없습니다."} />
                      </TableCell>
                    </TableRow>
                  ) : (
                    visibleRows?.map((row, index) => (
                      <TableRow
                        hover
                        key={row.groupIdx}
                        sx={{
                          "&": { height: 45, cursor: "pointer" },
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        role="checkbox"
                        tabIndex={-1}
                        onClick={(e) => handleClickRow(e, row)}
                      >
                        <TableCell padding="none" align="center">
                          <Checkbox size="small" color="primary" onClick={(event) => handleCheckClick(event, row)} checked={isSelected(row.groupIdx)} />
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {/* {calcRowNum(index, pageInfo, perPage)} */}
                          {row.groupIdx}
                        </TableCell>

                        <TableCell padding="none" align="center">
                          <Link
                            to={row.roomUseYN === "Y" ? `/acghr_hr/${company}/roomList${row.groupIdx}` : `/acghr_hr/${company}/testerList${row.groupIdx}`}
                            state={row}
                          >
                            {tagRender(row.groupStatus)}
                          </Link>
                        </TableCell>

                        {GROUP_TABLE_ROW[2].flag ? (
                          <TableCell padding="none" align="center">
                            <Typography fontSize={"0.9rem"}>{row.companyName}</Typography>
                          </TableCell>
                        ) : null}
                        <TableCell padding="none" align="center">
                          {row.partName}
                        </TableCell>

                        <TableCell padding="none">
                          <Link to={row.roomUseYN === "Y" ? `/acghr_hr/${company}/roomList${row.groupIdx}` : `/acghr_hr/${company}/testerList${row.groupIdx}`}>
                            <Stack direction="row" columnGap={0.5} alignItems="center">
                              {row.testGroupYN === "Y" && (
                                <Grid bgcolor="#777" px={0.5} borderRadius={0.5}>
                                  <Typography color="white" py={0.2} fontWeight={700} fontSize="9px">
                                    TEST
                                  </Typography>
                                </Grid>
                              )}
                              <Typography fontSize={"0.9rem"}>{row.groupName}</Typography>
                            </Stack>
                          </Link>
                        </TableCell>

                        <TableCell padding="none" align="center">
                          <Tooltip
                            arrow
                            placement="top"
                            title={
                              <>
                                <Typography variant="body1" fontWeight={700}>
                                  {`로그인 가능 시간 : ${loginEnableTime(row.examSAt)}`}
                                </Typography>
                              </>
                            }
                          >
                            <Box component={"span"}>
                              <Typography variant="body2">{dateIncludeTime(new Date(row.examSAt))}</Typography>
                            </Box>
                          </Tooltip>
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {dateIncludeTime(new Date(row.examEAt))}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {examMAPtype(row.examMAP)}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {row.normMemo || ""}
                        </TableCell>

                        <TableCell padding="none" align="center">
                          {row.roomUseYN === "Y" ? (
                            <Grid container justifyContent={"center"} columnGap={0.5}>
                              <PhotoCameraIcon style={{ color: "#3e6ad1" }} />
                            </Grid>
                          ) : (
                            <NoPhotographyIcon style={{ color: "#ff6594" }} />
                          )}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {row.total.toLocaleString()}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {row.preDone.toLocaleString()}
                        </TableCell>

                        <TableCell padding="none" align="center">
                          {row.done.toLocaleString()}
                        </TableCell>
                        <TableCell padding="none" align="center">
                          {row.inProgress.toLocaleString()}
                        </TableCell>

                        <TableCell padding="none" align="center">
                          <IconButton
                            size="small"
                            onClick={(e) => {
                              setSelectedRow(row);
                              handleUpdateGroupModal(e);
                            }}
                            sx={{
                              border: "1px solid #ddd9d996",
                              borderRadius: 2,
                            }}
                          >
                            <Settings size={18} strokeWidth={1.5} />
                          </IconButton>
                        </TableCell>
                        {companyInfo.companyIdx === 1 && (
                          <TableCell padding="none" align="center">
                            <ConfirmSwitch size="small" checked={row.approveYN === "Y" ? true : false} onClick={(e) => handleConfirmGroup(e, row)} />
                          </TableCell>
                        )}
                      </TableRow>
                    ))
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <Grid container alignItems="center" justifyContent="center">
              <Pagination
                size="small"
                count={pageInfo.totalPage}
                page={pageInfo.pageNo}
                onChange={(_, page) => {
                  setSearchGroupInfo((prev) => ({
                    ...prev,
                    pageNo: page,
                  }));
                }}
              />
            </Grid>
          </Paper>
        )}
      </Box>

      {/* 공고등록 */}
      <Dialog open={modalOn} onClose={handleModal} fullWidth scroll={"paper"} maxWidth="sm">
        <Suspense fallback={<LoadingSkeleton />}>
          <CreateGroupForm onClose={handleModal} />
        </Suspense>
      </Dialog>

      {/* 공고수정 */}
      <Dialog open={updateGroup} onClose={handleUpdateGroupModal} fullWidth scroll={"paper"} maxWidth="sm">
        <Suspense fallback={<LoadingSkeleton />}>
          <UpdateGroup selectedRow={selectedRow} onClose={handleUpdateGroupModal} setSelected={setSelected} />
        </Suspense>
      </Dialog>
      {/* 삭제된 공고 */}
      <Dialog open={removedGroupModalOn} onClose={handleRemovedGroup} fullWidth scroll={"paper"} maxWidth="md">
        <Suspense fallback={<LoadingSkeleton />}>
          <RemovedGroupModal onClose={handleRemovedGroup} />
        </Suspense>
      </Dialog>
    </>
  );
}
